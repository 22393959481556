import { FC } from 'react'

export const TelegramIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.02686 20.9108C9.57862 20.9108 9.8224 20.6584 10.1304 20.359L13.0731 17.4976L9.40246 15.2842"
      fill="currentColor"
    />
    <path
      d="M18.2964 21.8556L9.40212 15.2844L19.6362 6.04891C20.0854 5.65029 19.5381 5.45591 18.9419 5.81747L6.9 13.4145L8.75652 19.7709C8.81496 19.934 8.85552 20.0781 8.88552 20.1848L8.89866 20.2313C8.90748 20.2623 8.91492 20.2872 8.9211 20.3073C8.94786 20.3092 8.98266 20.3106 9.02694 20.3106V20.9106C8.46678 20.9106 8.42004 20.7448 8.30748 20.3457C8.2764 20.2354 8.24028 20.1072 8.18688 19.9598L6.31164 13.7857L0.856074 12.0829C-0.322091 11.7222 -0.33055 10.9126 1.12058 10.3306L22.3797 2.13316C23.3507 1.69235 24.2878 2.36637 23.9171 3.85247L20.2967 20.9133C20.0439 22.1257 19.3114 22.4156 18.2964 21.8556Z"
      fill="currentColor"
    />
  </svg>
)
