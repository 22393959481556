import coin from 'assets/coin.png'
import { TelegramIcon } from 'components/Icons/Telegram'
import { TwitterIcon } from 'components/Icons/Twitter'
import { transparentize } from 'polished'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background: ${({ theme }) => transparentize(0.5, theme.bg1)};
  backdrop-filter: blur(6px);
`

const Section = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 32px;
`

const Coin = styled.img`
  width: 250px;
  height: 250px;

  animation: float 3s ease-in-out infinite;

  @keyframes float {
    0% {
      transform: translatey(0px) scale(1.02);
    }

    50% {
      transform: translatey(10px) scale(1);
    }

    100% {
      transform: translatey(0px) scale(1.02);
    }
  }
`

const Title = styled.h1`
  text-align: center;
  letter-spacing: 5px;
  font-size: 36px;
  text-shadow: 6px 4px 0 ${({ theme }) => theme.bg1};
`

const Subtitle = styled.h2`
  text-align: center;
  font-size: 24px;
  margin: -16px 0 32px;
`

const ButtonsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  gap: 24px;
  padding-bottom: 128px;

  & > a {
    width: 100%;
  }
`

const Button = styled.button`
  width: 100%;
  background: ${({ theme }) => theme.neutral1};
  color: ${({ theme }) => theme.bg1};
  border: none;
  transition: 125ms;
  outline: none;
  padding: 8px 0;
  font-size: 24px;
  font-weight: 900;

  &:enabled {
    cursor: pointer;
    box-shadow: 8px 8px 0 0 ${({ theme }) => theme.bg1};
  }

  &:disabled {
    opacity: 0.7;
    box-shadow: none;
    cursor: not-allowed;
  }

  &:hover:enabled {
    box-shadow: 0 0 0 0 ${({ theme }) => theme.bg1};
    transform: translate(4px, 4px);
  }
`

const SocialsWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  display: flex;
  justify-content: center;
  gap: 32px;
  align-items: center;
  width: 100%;

  & svg {
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.neutral1};
  }
`

const ADDRESS = '0x079337c58d486aec3f2c5648271345d7c54657a158d88a1e3bd3521129c7cc94'
const POOL = '0x7057d93102b656f398688b94f48d982f5e4bb81ded28e43251e44a7f4459567'

export default function HomePage() {
  const [sloganIndex, setSloganIndex] = useState(0)

  const links = useMemo(
    () => [
      {
        name: 'CHART',
        path: `https://www.geckoterminal.com/fr/starknet-alpha/pools/${POOL}`,
      },
      {
        name: 'BUY',
        path: `https://app.avnu.fi/en?tokenFrom=0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7&tokenTo=${ADDRESS}`,
      },
      {
        name: 'ADDRESS',
        path: `https://voyager.online/contract/${ADDRESS}`,
      },
      {
        name: 'CODE',
        path: `https://github.com/Powni/token-contract`,
      },
      {
        name: 'LIQUIDITY',
        path: `https://voyager.online/contract/0x7057d93102b656f398688b94f48d982f5e4bb81ded28e43251e44a7f4459567#tokenHolders`,
      },
      {
        name: 'DAPP (soon)',
      },
    ],
    []
  )

  const slogans = useMemo(
    () => [
      'Fair launch',
      '0 Team Allocation',
      'Next x1000',
      '0 Whitelist',
      'Liquidity locked',
      '0 Bullshit',
      'On Starknet',
      '0 Presale',
    ],
    []
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSloganIndex((value) => (value + 1) % slogans.length)
    }, 400)

    return () => clearInterval(intervalId)
  }, [slogans.length, setSloganIndex])

  return (
    <Wrapper>
      <Section>
        <Coin src={coin} />

        <Title>POWNI</Title>

        <Subtitle>{slogans[sloganIndex]}</Subtitle>

        <ButtonsWrapper>
          {links.map(({ name, path }) =>
            path ? (
              <Link to={path} key={path} target="_blank">
                <Button>{name}</Button>
              </Link>
            ) : (
              <Button key={name} disabled>
                {name}
              </Button>
            )
          )}
        </ButtonsWrapper>
      </Section>

      <SocialsWrapper>
        <Link to="https://twitter.com/PowniLegend" target="_blank">
          <TwitterIcon />
        </Link>

        <Link to="https://t.me/+4r2B1Ta-fidmMDMx" target="_blank">
          <TelegramIcon />
        </Link>
      </SocialsWrapper>
    </Wrapper>
  )
}
