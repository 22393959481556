/* eslint-disable @typescript-eslint/no-var-requires */
import './theme/global.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import ThemeProvider, { ThemedGlobalStyle } from 'theme'

import App from './App'

window.Buffer = window.Buffer || require('buffer').Buffer

const container = document.getElementById('root')
if (!container) throw 'Undefined #root container'

const root = createRoot(container)
root.render(
  <ThemeProvider>
    <ThemedGlobalStyle />
    <App />
  </ThemeProvider>
)
